import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import AdministrationBlockLayout from '../block-layout';
export default class UpdateOrganizationImportFormat extends Component {
    @service
    intl;
    @service
    notifications;
    @service
    router;
    @service
    store;
    @action
    async uploadOrganizationImportFile(files1) {
        this.notifications.clearAll();
        const adapter1 = this.store.adapterFor('import-files');
        try {
            await adapter1.updateOrganizationImportFormat(files1);
            this.notifications.success(this.intl.t('components.administration.organization-import-format.notifications.success'));
        } catch (errorResponse1) {
            const errors1 = errorResponse1.errors;
            if (!errors1) {
                return this.notifications.error(this.intl.t('common.notifications.generic-error'));
            }
            errors1.forEach((error1)=>{
                switch(error1.code){
                    case 'MISSING_REQUIRED_FIELD_NAMES':
                        this.notifications.error(`${error1.meta}`, {
                            autoClear: false
                        });
                        break;
                    default:
                        this.notifications.error(error1.detail, {
                            autoClear: false
                        });
                }
            });
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.organization-import-format.title"}}
      @description={{t "components.administration.organization-import-format.description"}}
    >
      <PixButtonUpload
        @id="organization-import-file-upload"
        @onChange={{this.uploadOrganizationImportFile}}
        accept=".json"
      >
        {{t "components.administration.organization-import-format.upload-button"}}
      </PixButtonUpload>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
