import { template } from "@ember/template-compiler";
import CertificationScoringConfiguration from './certification-scoring-configuration';
import CompetenceScoringConfiguration from './competence-scoring-configuration';
import FlashAlgorithmConfiguration from './flash-algorithm-configuration';
import ScoWhitelistConfiguration from './sco-whitelist-configuration';
import ScoringSimulator from './scoring-simulator';
export default template(`
  <ScoWhitelistConfiguration />
  <CertificationScoringConfiguration />
  <CompetenceScoringConfiguration />
  <FlashAlgorithmConfiguration @model={{@model}} />
  <ScoringSimulator />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
