import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
import AdministrationBlockLayout from '../block-layout';
export default class OrganizationTagsImport extends Component {
    @service
    intl;
    @service
    notifications;
    @service
    session;
    @service
    errorResponseHandler;
    @action
    async importOrganizationTags(files1) {
        this.notifications.clearAll();
        let response1;
        try {
            const fileContent1 = files1[0];
            const token1 = this.session.data.authenticated.access_token;
            response1 = await window.fetch(`${ENV.APP.API_HOST}/api/admin/organizations/import-tags-csv`, {
                headers: {
                    Authorization: `Bearer ${token1}`,
                    'Content-Type': 'text/csv',
                    Accept: 'application/json'
                },
                method: 'POST',
                body: fileContent1
            });
            if (response1.ok) {
                this.notifications.success(this.intl.t('components.administration.organization-tags-import.notifications.success'));
                return;
            } else {
                const json1 = await response1.json();
                const error1 = json1.errors[0];
                if (error1.code === 'TAG_NOT_FOUND') {
                    this.notifications.error(this.intl.t('components.administration.organization-tags-import.notifications.errors.tag-not-found', error1.meta));
                    return;
                }
                this.errorResponseHandler.notify(json1);
            }
        } catch (error1) {
            this.notifications.error(this.intl.t('common.notifications.generic-error'));
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.organization-tags-import.title"}}
      @description={{t "components.administration.organization-tags-import.description"}}
    >
      <PixButtonUpload
        @id="organization-tags-import-file-upload"
        @onChange={{this.importOrganizationTags}}
        @variant="secondary"
        accept=".csv"
      >

        {{t "components.administration.organization-tags-import.upload-button"}}
      </PixButtonUpload>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
