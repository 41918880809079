import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Area from '../common/tubes-details/area';
export default class CappedTubesCriterion extends Component {
    get areasForView() {
        return this.args.targetProfile.hasMany('areas').value().sortBy('code').map((area1)=>({
                area: area1,
                competences: this._buildCompetencesViewModel(area1.hasMany('competences').value())
            })).filter(({ competences: competences1 })=>competences1.length !== 0).map(({ area: area1, competences: competences1 })=>({
                title: `${area1.code} · ${area1.title}`,
                color: area1.color,
                competences: competences1
            }));
    }
    _buildCompetencesViewModel(competences1) {
        return competences1.sortBy('index').map((competence1)=>({
                competence: competence1,
                thematics: this._buildThematicsViewModel(competence1.hasMany('thematics').value())
            })).filter(({ thematics: thematics1 })=>thematics1.length !== 0).map(({ competence: competence1, thematics: thematics1 })=>({
                title: `${competence1.index} ${competence1.name}`,
                thematics: thematics1
            }));
    }
    _buildThematicsViewModel(thematics1) {
        return thematics1.sortBy('index').map((thematic1)=>({
                thematic: thematic1,
                tubes: this._buildTubesViewModel(thematic1.hasMany('tubes').value())
            })).filter(({ tubes: tubes1 })=>tubes1.length !== 0).map(({ thematic: thematic1, tubes: tubes1 })=>({
                name: thematic1.name,
                nbTubes: tubes1.length,
                tubes: tubes1
            }));
    }
    _buildTubesViewModel(tubes1) {
        return tubes1.sortBy('practicalName').map((tube1)=>({
                tube: tube1,
                cappedTube: this.args.criterion.cappedTubes.find(({ tubeId: tubeId1 })=>tubeId1 === tube1.id)
            })).filter(({ cappedTube: cappedTube1 })=>cappedTube1).map(({ tube: tube1, cappedTube: cappedTube1 })=>({
                id: tube1.id,
                title: `${tube1.name} : ${tube1.practicalTitle}`,
                level: cappedTube1.level,
                tablet: tube1.tablet,
                mobile: tube1.mobile
            }));
    }
    static{
        template(`
    <div class="card__title">
      {{#if @criterion.name}}
        <strong>{{@criterion.name}}</strong>
      {{else}}
        Critère
      {{/if}}
    </div>

    <div class="card__content">
      <p class="capped-tubes-criterion__text" data-testid="toujourstriste">
        L'évalué doit obtenir
        <strong>{{@criterion.threshold}}%</strong>
        {{#if @criterion.name}}
          sur le groupe
          <strong>{{@criterion.name}}</strong>
          possédant
        {{else}}
          sur tous
        {{/if}}
        les sujets plafonnés par niveau suivants :
      </p>

      <div class="badge-form-criterion">
        {{#each this.areasForView as |area|}}
          <Area
            @title={{area.title}}
            @color={{area.color}}
            @competences={{area.competences}}
            @displayDeviceCompatibility={{true}}
          />
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
