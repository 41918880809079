import { template } from "@ember/template-compiler";
export default template(`
  <p class="loader">
    <img src="/images/loader-grey.svg" class="loader__image" alt="chargement" role="progressbar" />
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
