import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Area from '../../common/tubes-details/area';
export default class Details extends Component {
    @service
    store;
    @tracked
    areas = [];
    constructor(){
        super(...arguments);
        Promise.resolve(this.args.areas).then((areas1)=>{
            this.areas = areas1;
        });
    }
    get areasList() {
        return this.areas.sortBy('code').map((area1)=>this.buildAreaViewModel(area1));
    }
    buildAreaViewModel(area1) {
        return {
            title: `${area1.code} · ${area1.title}`,
            color: area1.color,
            competences: area1.sortedCompetences.map((competence1)=>this.buildCompetenceViewModel(competence1))
        };
    }
    buildCompetenceViewModel(competence1) {
        return {
            id: competence1.id,
            title: `${competence1.index} ${competence1.name}`,
            thematics: competence1.hasMany('thematics').value().map((thematic1)=>this.buildThematicViewModel(thematic1))
        };
    }
    buildThematicViewModel(thematic1) {
        return {
            name: thematic1.name,
            nbTubes: thematic1.hasMany('triggerTubes').value().length,
            tubes: thematic1.hasMany('triggerTubes').value().map((triggerTube1)=>{
                return this.buildTubeViewModel(triggerTube1.get('tube'), triggerTube1.level);
            })
        };
    }
    buildTubeViewModel(tube1, level1) {
        return {
            id: tube1.get('id'),
            title: `${tube1.name} : ${tube1.practicalTitle}`,
            level: level1
        };
    }
    static{
        template(`
    {{#each this.areasList as |area|}}
      <Area @title={{area.title}} @color={{area.color}} @competences={{area.competences}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
