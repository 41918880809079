import { template } from "@ember/template-compiler";
import CampaignsImport from './campaigns-import';
import SwapCampaignCodes from './swap-campaign-codes';
import UpdateCampaignCode from './update-campaign-code';
export default template(`
  <CampaignsImport />

  <SwapCampaignCodes />

  <UpdateCampaignCode />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
