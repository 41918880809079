import { template } from "@ember/template-compiler";
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { concat } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import partition from 'lodash/partition';
import ENV from 'pix-admin/config/environment';
export default class CertifiedProfile extends Component {
    @tracked
    certifiedAreas = [];
    @tracked
    certifiedCompetences = [];
    @tracked
    certifiedTubes = [];
    @tracked
    certifiedSkills = [];
    constructor(){
        super(...arguments);
        this.args.certifiedProfile.certifiedAreas.then((certifiedAreas1)=>{
            this.certifiedAreas = certifiedAreas1;
        });
        this.args.certifiedProfile.certifiedCompetences.then((certifiedCompetences1)=>{
            this.certifiedCompetences = certifiedCompetences1;
        });
        this.args.certifiedProfile.certifiedTubes.then((certifiedTubes1)=>{
            this.certifiedTubes = certifiedTubes1;
        });
        this.args.certifiedProfile.certifiedSkills.then((certifiedSkills1)=>{
            this.certifiedSkills = certifiedSkills1;
        });
    }
    get certifiedCompetenceList() {
        const competencesOfCertifiedAreas1 = this.certifiedAreas.toArray().flatMap((certifiedArea1)=>this._buildCertifiedCompetencesOfCertifiedArea(certifiedArea1));
        const [pixCompetences1, nonPixCompetences1] = partition(competencesOfCertifiedAreas1, {
            origin: 'Pix'
        });
        const certifiedCompetencesGroupedByOriginWithNonPixCompetencesFirst1 = [
            ...nonPixCompetences1,
            ...pixCompetences1
        ];
        return certifiedCompetencesGroupedByOriginWithNonPixCompetencesFirst1;
    }
    get difficultyLevels() {
        return Array.from({
            length: ENV.APP.MAX_LEVEL
        }, (_1, i1)=>i1 + 1);
    }
    _buildCertifiedCompetencesOfCertifiedArea(certifiedArea1) {
        return this.certifiedCompetences.filter((certifiedCompetence1)=>certifiedCompetence1.areaId === certifiedArea1.id).map((certifiedCompetence1)=>({
                name: certifiedCompetence1.name,
                certifiedArea: certifiedArea1,
                certifiedTubes: this._buildCertifiedTubeOfCertifiedCompetence(certifiedCompetence1.id),
                origin: certifiedCompetence1.origin
            }));
    }
    _buildCertifiedTubeOfCertifiedCompetence(certifiedCompetenceId1) {
        return this.certifiedTubes.filter((certifiedTube1)=>certifiedTube1.competenceId === certifiedCompetenceId1).map((certifiedTube1)=>({
                name: certifiedTube1.name,
                certifiedSkills: this._buildCertifiedTubeSkillsByLevel(certifiedTube1.id)
            }));
    }
    _buildCertifiedTubeSkillsByLevel(certifiedTubeId1) {
        const tubeSkills1 = this.certifiedSkills.filter((certifiedSkill1)=>certifiedSkill1.tubeId === certifiedTubeId1);
        return this.difficultyLevels.map((_1, index1)=>{
            return tubeSkills1.find((skill1)=>skill1.difficulty === index1 + 1);
        });
    }
    static{
        template(`
    {{#each this.certifiedCompetenceList as |certifiedCompetence|}}
      <section class="page-section competence">
        <span class="competence__border competence__border--{{certifiedCompetence.certifiedArea.color}}"></span>
        <div>
          <header class="competence__header">
            <h2 class="competence__title">{{certifiedCompetence.name}}</h2>
            <sub class="competence__subtitle">{{certifiedCompetence.certifiedArea.name}}</sub>
          </header>
          <table class="table-admin">
            <thead>
              <tr>
                <th class="table__column table__column--wide">Sujet</th>
                {{#each this.difficultyLevels as |difficultyLevel|}}
                  <th class="table__column table__column--small table__column--center">Niveau {{difficultyLevel}}</th>
                {{/each}}
              </tr>
            </thead>
            <tbody>
              {{#each certifiedCompetence.certifiedTubes as |certifiedTube|}}
                <tr>
                  <td>{{certifiedTube.name}}</td>
                  {{#each certifiedTube.certifiedSkills as |certifiedSkill|}}
                    <td class="table__column--center skill-column">
                      {{#if certifiedSkill.hasBeenAskedInCertif}}
                        <PixTooltip @position="bottom">
                          <:triggerElement>
                            <FaIcon
                              @icon="check-double"
                              class="skill-column--tested-in-certif"
                              aria-label={{certifiedSkill.name}}
                            />
                          </:triggerElement>
                          <:tooltip>{{concat certifiedSkill.id " " certifiedSkill.name}}</:tooltip>
                        </PixTooltip>
                      {{else if certifiedSkill}}
                        <PixTooltip @position="bottom">
                          <:triggerElement>
                            <FaIcon @icon="check" class="skill-column--check" aria-label={{certifiedSkill.name}} />
                          </:triggerElement>
                          <:tooltip>{{concat certifiedSkill.id " " certifiedSkill.name}}</:tooltip>
                        </PixTooltip>
                      {{else}}
                        <FaIcon @icon="xmark" class="skill-column--uncheck" />
                      {{/if}}
                    </td>
                  {{/each}}
                </tr>
              {{/each}}
            </tbody>
          </table>
        </div>
      </section>
    {{else}}
      <section class="page-section">
        <div class="table__empty">Profil certifié vide.</div>
      </section>
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
