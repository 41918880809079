import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import get from 'lodash/get';
import ConfirmPopup from '../confirm-popup';
import AuthenticationMethod from './user-detail-personal-information/authentication-method';
const typesLabel = {
    EMAIL: 'Adresse e-mail',
    USERNAME: 'Identifiant',
    POLE_EMPLOI: 'France Travail',
    GAR: 'Médiacentre',
    CNAV: 'CNAV',
    FWB: 'Fédération Wallonie-Bruxelles',
    PAYSDELALOIRE: 'Pays de la Loire'
};
export default class UserDetailAuthenticationMethodsComponent extends Component {
    @tracked
    displayRemoveAuthenticationMethodModal = false;
    @tracked
    isLoading = false;
    @tracked
    authenticationMethodType = null;
    @service
    notifications;
    get translatedType() {
        return typesLabel[this.authenticationMethodType];
    }
    @action
    toggleDisplayRemoveAuthenticationMethodModal(type1) {
        this.authenticationMethodType = type1;
        this.displayRemoveAuthenticationMethodModal = !this.displayRemoveAuthenticationMethodModal;
    }
    @action
    async removeAuthenticationMethod() {
        this.isLoading = true;
        try {
            await this.args.removeAuthenticationMethod(this.authenticationMethodType);
        } catch (response1) {
            let errorMessage1 = 'Une erreur est survenue !';
            if (get(response1, 'errors[0].status') === '403') {
                errorMessage1 = 'Vous ne pouvez pas supprimer la dernière méthode de connexion de cet utilisateur';
            }
            this.notifications.error(errorMessage1);
        } finally{
            this.isLoading = false;
            this.toggleDisplayRemoveAuthenticationMethodModal(null);
        }
    }
    static{
        template(`
    <section class="page-section user-authentication-method">
      <AuthenticationMethod
        @user={{@user}}
        @toggleDisplayRemoveAuthenticationMethodModal={{this.toggleDisplayRemoveAuthenticationMethodModal}}
        @addPixAuthenticationMethod={{@addPixAuthenticationMethod}}
        @reassignAuthenticationMethod={{@reassignAuthenticationMethod}}
      />
    </section>

    <ConfirmPopup
      @message="Suppression de la méthode de connexion suivante : {{this.translatedType}}"
      @title="Confirmer la suppression"
      @submitTitle="Oui, je supprime"
      @confirm={{this.removeAuthenticationMethod}}
      @cancel={{this.toggleDisplayRemoveAuthenticationMethodModal}}
      @show={{this.displayRemoveAuthenticationMethodModal}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
