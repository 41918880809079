import { template } from "@ember/template-compiler";
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import showdown from 'showdown';
import xss from 'xss';
const xssWhitelist = {
    ...xss.whiteList,
    style: [],
    span: [
        'style'
    ],
    th: [
        'style'
    ],
    td: [
        'style'
    ],
    tr: [
        'style'
    ],
    table: [
        'style'
    ],
    a: [
        'href',
        'rel',
        'target',
        'title'
    ]
};
const showdownOptions = {
    openLinksInNewWindow: true,
    strikethrough: true
};
export default class SafeMarkdownToHtml extends Component {
    toHtml(markdown1) {
        const converter1 = new showdown.Converter(showdownOptions);
        const unsafeHtml1 = converter1.makeHtml(markdown1);
        const html1 = xss(unsafeHtml1, {
            whiteList: xssWhitelist
        });
        return htmlSafe(html1);
    }
    static{
        template(`
    <div class={{@class}} ...attributes>
      {{this.toHtml @markdown}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
