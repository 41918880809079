import { template } from "@ember/template-compiler";
export default template(`
  <th rowspan={{@nbTubes}}>
    {{@name}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
