export const fapixTachometer = {
  prefix: 'fapix',
  iconName: 'tachometer',
  icon: [
    18,
    16,
    [],
    '',
    'M7.928 13.3a1.414 1.414 0 1 0 2.82-.21c-.034-.457-.058-.97.237-1.321l1.93-2.297a.355.355 0 0 0-.543-.457l-1.943 2.306c-.291.346-.79.414-1.24.462A1.412 1.412 0 0 0 7.93 13.3ZM8.7 5.971V3.685a.386.386 0 0 0-.413-.39 8.955 8.955 0 0 0-5.124 2.128.386.386 0 0 0-.015.567l1.35 1.351a.42.42 0 0 0 .562.021 6.26 6.26 0 0 1 3.64-1.39ZM14.851 5.99a.386.386 0 0 0-.015-.568 8.955 8.955 0 0 0-5.123-2.128.386.386 0 0 0-.413.39V5.59a.42.42 0 0 0 .38.41 6.26 6.26 0 0 1 3.26 1.355.42.42 0 0 0 .56-.02l1.351-1.345ZM2.4 12.871a.3.3 0 0 0 .216-.09.3.3 0 0 0 .084-.21v-.3c0-1.439.495-2.83 1.395-3.943a.42.42 0 0 0-.02-.56l-1.35-1.354a.386.386 0 0 0-.568.015A8.97 8.97 0 0 0 0 12.27v.318a.3.3 0 0 0 .3.282h2.1ZM15.567 6.121l-1.641 1.647a.42.42 0 0 0-.02.56 6.273 6.273 0 0 1 1.394 3.944v.3a.3.3 0 0 0 .084.218.3.3 0 0 0 .216.082h2.1a.3.3 0 0 0 .3-.283v-.318a8.97 8.97 0 0 0-2.433-6.15Z',
  ],
};
