import { template } from "@ember/template-compiler";
import NewTag from './new-tag';
import OrganizationTagsImport from './organization-tags-import';
import OrganizationsImport from './organizations-import';
import UpdateOrganizationsInBatch from './update-organizations-in-batch';
export default template(`
  <NewTag />

  <OrganizationTagsImport />

  <OrganizationsImport />

  <UpdateOrganizationsInBatch />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
