import { template } from "@ember/template-compiler";
import { concat, fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import CertificationInfoField from './info-field';
export default class CertificationCompetenceList extends Component {
    competenceList = [
        '1.1',
        '1.2',
        '1.3',
        '2.1',
        '2.2',
        '2.3',
        '2.4',
        '3.1',
        '3.2',
        '3.3',
        '3.4',
        '4.1',
        '4.2',
        '4.3',
        '5.1',
        '5.2'
    ];
    get indexedValues() {
        const competences1 = this.args.competences;
        const indexedCompetences1 = competences1.reduce((result1, value1)=>{
            result1[value1.index] = value1;
            return result1;
        }, {});
        const competencesList1 = this.competenceList;
        const scores1 = [];
        const levels1 = [];
        let index1 = 0;
        competencesList1.forEach((value1)=>{
            scores1[index1] = indexedCompetences1[value1] ? indexedCompetences1[value1].score : null;
            levels1[index1] = indexedCompetences1[value1] ? indexedCompetences1[value1].level : null;
            index1++;
        });
        return {
            scores: scores1,
            levels: levels1
        };
    }
    @action
    onScoreChange(index1, event1) {
        const list1 = this.competenceList;
        this.args.onUpdateScore(list1[index1], event1.target.value);
    }
    @action
    onLevelChange(index1, event1) {
        const list1 = this.competenceList;
        this.args.onUpdateLevel(list1[index1], event1.target.value);
    }
    static{
        template(`
    {{#if @edition}}
      {{#each this.competenceList as |competenceItem key|}}
        <div class="competence-list-edited" aria-label="Informations de la compétence {{competenceItem}} éditable">
          <CertificationInfoField
            @fieldId={{concat "certification-info-score_" key}}
            @value={{get this.indexedValues.scores key}}
            @edition={{@edition}}
            @label={{competenceItem}}
            @suffix="Pix"
            {{on "change" (fn this.onScoreChange key)}}
          />

          <CertificationInfoField
            @fieldId={{concat "certification-info-level_" key}}
            @value={{get this.indexedValues.levels key}}
            @edition={{@edition}}
            @label="Niveau:"
            {{on "change" (fn this.onLevelChange key)}}
          />
        </div>
      {{/each}}
    {{else}}
      <table aria-label="{{t 'pages.certifications.certification.result.table.label'}}" class="table-admin">
        <thead>
          <tr>
            <th scope="col">{{t "pages.certifications.certification.result.table.headers.competence"}}</th>
            {{#if @shouldDisplayPixScore}}
              <th scope="col">{{t "pages.certifications.certification.result.table.headers.score"}}</th>
            {{/if}}
            <th scope="col">{{t "pages.certifications.certification.result.table.headers.level"}}</th>
          </tr>
        </thead>
        <tbody>
          {{#each @competences as |competence|}}
            <tr>
              <th scope="row">{{competence.index}}</th>
              {{#if @shouldDisplayPixScore}}
                <td>{{competence.score}}</td>
              {{/if}}
              <td>{{competence.level}}</td>
            </tr>
          {{/each}}
        </tbody>
      </table>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
