import { deprecate } from '@ember/debug';
function initializeStore(application) {
  application.registerOptionsForType('serializer', {
    singleton: false
  });
  application.registerOptionsForType('adapter', {
    singleton: false
  });
}
function setupContainer(application) {
  initializeStore(application);
}
deprecate('Importing from `ember-data/setup-container` is deprecated without replacement', false, {
  id: 'ember-data:deprecate-legacy-imports',
  for: 'ember-data',
  until: '6.0',
  since: {
    enabled: '5.2',
    available: '5.2'
  }
});
export { setupContainer as default };