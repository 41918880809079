export const fapixPersonExport = {
  prefix: 'fapix',
  iconName: 'person-export',
  icon: [
    17,
    20,
    [],
    '',
    'M10.231 6.25c0 1.998-1.665 3.617-3.72 3.617S2.79 8.248 2.79 6.25c0-1.997 1.665-3.617 3.72-3.617s3.72 1.62 3.72 3.617ZM0 16.262c0-2.783 2.32-5.038 5.182-5.038H7.84c.42 0 .828.048 1.218.14-.874.972-1.461 2.333-1.461 4.29.948-1.137 2.796-1.934 4.375-2.433a4.927 4.927 0 0 1 1.05 3.041c0 .464-.386.84-.863.84H.863a.852.852 0 0 1-.863-.84ZM14.577 8.437a.5.5 0 0 0-.832.374v.553c-1.689.429-4.644 2.426-5.426 4.844 1.954-1.715 5.265-2.377 5.426-2.37v.533a.5.5 0 0 0 .832.374l2.003-1.78a.5.5 0 0 0 0-.748l-2.003-1.78Z',
  ],
};
