import { template } from "@ember/template-compiler";
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixCollapsible from '@1024pix/pix-ui/components/pix-collapsible';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { eq } from 'ember-truth-helpers';
import Header from '../../table/header';
import Thematic from './thematic';
import Tube from './tube';
export default class Competence extends Component {
    @action
    onChange(event1) {
        event1.target.checked ? this.checkAllTubes() : this.uncheckAllTubes();
    }
    get competenceTubes() {
        const thematics1 = this.args.competence.hasMany('thematics').value();
        const tubes1 = thematics1.map((thematic1)=>thematic1.hasMany('tubes').value());
        return tubes1.flat();
    }
    get selectedTubeIds() {
        return this.args.selectedTubeIds;
    }
    get isChecked() {
        return this.competenceTubes.some(({ id: id1 })=>this.selectedTubeIds.includes(id1));
    }
    get isIndeterminate() {
        return this.competenceTubes.some(({ id: id1 })=>!this.selectedTubeIds.includes(id1));
    }
    checkAllTubes() {
        this.competenceTubes.forEach((tube1)=>{
            this.args.checkTube(tube1);
        });
    }
    uncheckAllTubes() {
        this.competenceTubes.forEach((tube1)=>{
            this.args.uncheckTube(tube1);
        });
    }
    static{
        template(`
    <div class="competence-container">
      <PixCollapsible @title="{{@competence.index}} {{@competence.name}}">
        <div class="panel">
          <table class="table content-text content-text--small select-tube-table">
            <caption class="screen-reader-only">Sélection des sujets</caption>
            <thead>
              <tr>
                <Header @size="medium" scope="col">
                  <label>
                    <PixCheckbox
                      id="competence-{{@competence.id}}"
                      @checked={{this.isChecked}}
                      @isIndeterminate={{this.isIndeterminate}}
                      {{on "change" this.onChange}}
                    >
                      <:label>Thématiques</:label>
                    </PixCheckbox>
                  </label>
                </Header>
                <Header @size="wide" scope="col">
                  <p>Sujets</p>
                </Header>
                <Header @size="small" scope="col">
                  <p>Niveau</p>
                </Header>
                {{#if @displayDeviceCompatibility}}
                  <Header @size="medium" @align="center" scope="col">
                    <p>Compatibilité</p>
                  </Header>
                {{/if}}
              </tr>
            </thead>

            <tbody>
              {{#each @competence.sortedThematics as |thematic|}}
                {{#each thematic.tubes as |tube index|}}
                  <tr class={{if (eq index 0) "row-thematic"}} aria-label="Sujet">
                    {{#if (eq index 0)}}
                      <Thematic
                        @thematic={{thematic}}
                        @selectedTubeIds={{@selectedTubeIds}}
                        @checkTube={{@checkTube}}
                        @uncheckTube={{@uncheckTube}}
                      />
                    {{/if}}
                    <Tube
                      @tube={{tube}}
                      @setLevelTube={{@setLevelTube}}
                      @selectedTubeIds={{@selectedTubeIds}}
                      @checkTube={{@checkTube}}
                      @uncheckTube={{@uncheckTube}}
                      @tubeLevels={{@tubeLevels}}
                      @displayDeviceCompatibility={{@displayDeviceCompatibility}}
                      @displaySkillDifficultyAvailability={{@displaySkillDifficultyAvailability}}
                    />
                  </tr>
                {{/each}}
              {{/each}}
            </tbody>
          </table>
        </div>
      </PixCollapsible>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
