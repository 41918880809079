import { template } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
export default class TickOrCross extends Component {
    get icon() {
        return this.args.isTrue ? 'check' : 'times';
    }
    get class() {
        return this.args.isTrue ? 'tick-or-cross--valid' : 'tick-or-cross--invalid';
    }
    static{
        template(`<FaIcon @icon={{this.icon}} class="tick-or-cross {{this.class}}" />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
