import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import Card from '../card';
export default template(`
  <Card class="admin-form__card" @title="Évaluation terminée">
    <p>🎉 Évaluation terminée 🎉</p>
    <PixButton @variant="primary" @triggerAction={{@reset}}>
      Recommencer ?
    </PixButton>
  </Card>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
