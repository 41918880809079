import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Card from '../card';
export default class PreviousChallenges extends Component {
    @service
    intl;
    displayBooleanState = (bool1)=>{
        const yes1 = this.intl.t('common.words.yes');
        const no1 = this.intl.t('common.words.no');
        return bool1 ? yes1 : no1;
    };
    incrementIndex(index1) {
        return index1 + 1;
    }
    static{
        template(`
    <section class="admin-form__content">
      <Card class="admin-form__card previous-challenges" @title="Précédentes épreuves">
        <div class="previous-challenges__container">
          {{#each @challenges as |challenge index|}}
            <div class="previous-challenges__result {{challenge.result}}">
              <p class="previous-challenges__result__count">Épreuve {{this.incrementIndex index}}</p>
              <h2>{{challenge.skill.name}}</h2>
              <p class="previous-challenges__result__focused">Focus :
                {{this.displayBooleanState challenge.focused}}
              </p>
              <p class="previous-challenges__result__timed">Temps imparti:
                {{this.displayBooleanState challenge.timer}}
              </p>
            </div>
          {{/each}}
        </div>
      </Card>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
