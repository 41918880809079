import { template } from "@ember/template-compiler";
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import Component from '@glimmer/component';
export default class StageLevelSelect extends Component {
    get levelOptions() {
        return this.args.availableLevels.map((level1)=>({
                value: level1.toString(),
                label: level1.toString()
            }));
    }
    static{
        template(`
    <PixSelect
      ...attributes
      @options={{this.levelOptions}}
      @onChange={{@onChange}}
      @value={{@value}}
      @id={{@id}}
      @isDisabled={{@isDisabled}}
      @screenReaderOnly={{true}}
      @hideDefaultOption={{true}}
    >
      <:label>{{@label}}</:label>
    </PixSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
