import { template } from "@ember/template-compiler";
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { lt } from 'ember-truth-helpers';
import Card from '../card';
import { SMART_RANDOM_STEPS } from './SMART_RANDOM_STEPS';
const SKILLS_STATUSES = {
    MISSING: 'missing',
    CURRENT: 'current',
    PRESENT: 'present',
    ELIMINATED: 'eliminated'
};
const PRIMARY_COLOR = 'primary';
const NEUTRAL_COLOR = 'neutral';
export default class TubesViewer extends Component {
    levels = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8
    ];
    @tracked
    showSkillsRewards = true;
    get steps() {
        return this.args.smartRandomLog.steps;
    }
    get predictedLevel() {
        return this.args.smartRandomLog.predictedLevel;
    }
    @action
    getStepTagColor(stepIndex1) {
        return stepIndex1 <= this.args.displayedStepIndex ? PRIMARY_COLOR : NEUTRAL_COLOR;
    }
    @action
    getEliminatedSkillsByStepCount(stepIndex1) {
        const currentStep1 = this.steps[stepIndex1];
        const currentStepSkillsCount1 = currentStep1.outputSkills.length;
        if (stepIndex1 === 0) return this.args.totalNumberOfSkills - currentStepSkillsCount1;
        const previousStep1 = this.steps[stepIndex1 - 1];
        const previousStepSkillsCount1 = previousStep1.outputSkills.length;
        return previousStepSkillsCount1 - currentStepSkillsCount1;
    }
    @action
    getRemainingSkillsCountAfterStep(stepIndex1) {
        return this.steps[stepIndex1].outputSkills.length;
    }
    @action
    getSkillStatus(tube1, level1) {
        const skillInTube1 = tube1.skills.find((skill1)=>skill1.difficulty === level1);
        if (!skillInTube1) return SKILLS_STATUSES.MISSING;
        const isCurrentSkill1 = this.isSkillTheCurrentSkill(skillInTube1);
        if (isCurrentSkill1) return SKILLS_STATUSES.CURRENT;
        const knowledgeElementForSkill1 = this.knowledgeElementForSkill(skillInTube1);
        if (knowledgeElementForSkill1) return knowledgeElementForSkill1.status;
        const skillInSelectedStep1 = this.isSkillInSelectedStep(skillInTube1);
        if (!skillInSelectedStep1) return SKILLS_STATUSES.ELIMINATED;
        return SKILLS_STATUSES.PRESENT;
    }
    @action
    getSkillReward(tube1, level1) {
        const skillInTube1 = tube1.skills.find((skill1)=>skill1.difficulty === level1);
        if (!skillInTube1) return '';
        const skillReward1 = this.args.smartRandomLog.skillRewards.find((skill1)=>skill1.skillId === skillInTube1.id);
        if (!skillReward1) return '';
        return skillReward1.reward.toFixed(1);
    }
    @action
    toggleShowSkillsRewards() {
        this.showSkillsRewards = !this.showSkillsRewards;
    }
    isSkillTheCurrentSkill(skill1) {
        return skill1.id === this.args.currentSkillId;
    }
    isSkillInSelectedStep(skill1) {
        return this.steps[this.args.displayedStepIndex].outputSkills.some((outputSkill1)=>outputSkill1.id === skill1.id);
    }
    knowledgeElementForSkill(skill1) {
        return this.args.knowledgeElements.find((knowledgeElement1)=>knowledgeElement1.skillId === skill1.id);
    }
    getStepName(stepName1) {
        return SMART_RANDOM_STEPS[stepName1].translatedName;
    }
    getStepDescription(stepName1) {
        return SMART_RANDOM_STEPS[stepName1].description;
    }
    static{
        template(`
    <Card class="admin-form__card" @title="Étapes de sélection">

      <ul class="tubes-viewer__steps">
        <li>
          <PixTag @color="orga">
            <p>État initial</p>
            <p>{{@totalNumberOfSkills}} acquis</p>
          </PixTag>
        </li>

        {{#each this.steps as |step index|}}
          <li aria-label={{this.getStepName step.name}} class={{if (lt index @displayedStepIndex) "selected" ""}}>

            <PixTooltip @isWide={{true}}>

              <:triggerElement>
                <button {{on "click" (fn @selectDisplayedStepIndex index)}} type="button">
                  <PixTag @color="{{this.getStepTagColor index}}">
                    <p>{{this.getStepName step.name}}</p>
                    <p>
                      {{this.getEliminatedSkillsByStepCount index}}
                      acquis filtrés /
                      {{this.getRemainingSkillsCountAfterStep index}}
                      restants
                    </p>
                  </PixTag>
                </button>
              </:triggerElement>

              <:tooltip>
                {{this.getStepDescription step.name}}
              </:tooltip>

            </PixTooltip>

          </li>
        {{/each}}
      </ul>

      <p>Niveau prédit de l'utilisateur: {{if this.predictedLevel this.predictedLevel "N/A"}}</p>

      <p>
        <PixCheckbox {{on "change" this.toggleShowSkillsRewards}} checked={{this.showSkillsRewards}}>
          <:label>Afficher la lucrativité des acquis</:label>
        </PixCheckbox>
      </p>

      <table class="tubes-viewer__table">

        <caption class="tubes-viewer__table__captions">
          <ul>
            <li class="tubes-viewer__table__caption">
              <span class="tubes-viewer__table__skill current" />Acquis en cours
            </li>
            <li class="tubes-viewer__table__caption">
              <span class="tubes-viewer__table__skill present" />Acquis non évalué
            </li>
            <li class="tubes-viewer__table__caption">
              <span class="tubes-viewer__table__skill validated" />Validé par l'utilisateur
            </li>
            <li class="tubes-viewer__table__caption">
              <span class="tubes-viewer__table__skill invalidated" />Invalidé par l'utilisateur
            </li>
            <li class="tubes-viewer__table__caption">
              <span class="tubes-viewer__table__skill eliminated" />Éliminé par les filtres
            </li>
          </ul>
        </caption>

        <thead>
          <tr>
            <th>Sujet</th>
            {{#each this.levels as |level|}}
              <th>{{level}}</th>
            {{/each}}
          </tr>
        </thead>

        <tbody>
          {{#each @tubes as |tube|}}
            <tr>
              <th class="tubes-viewer__table__name">{{tube.name}}</th>
              {{#each this.levels as |level|}}
                <td aria-label="{{tube.name}}{{level}}">
                  <span class="tubes-viewer__table__skill {{this.getSkillStatus tube level}}">
                    {{#if this.showSkillsRewards}}
                      {{this.getSkillReward tube level}}
                    {{/if}}
                  </span>
                </td>
              {{/each}}
            </tr>
          {{/each}}
        </tbody>

      </table>

    </Card>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
