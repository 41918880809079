import { template } from "@ember/template-compiler";
import PixTag from '@1024pix/pix-ui/components/pix-tag';
export default template(`
  <div class="certification-informations__tag--float">
    {{#if @record.isPublished}}
      <PixTag @color="success">Publiée</PixTag>
    {{/if}}
    {{#if @record.isCancelled}}
      <PixTag @color="error">Annulée</PixTag>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
