import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
export default template(`
  <tr aria-label={{@organization.name}}>
    <td>
      <LinkTo @route="authenticated.organizations.get" @model={{@organization.id}}>
        {{@organization.id}}
      </LinkTo>
    </td>
    <td>{{@organization.name}}</td>
    <td>{{@organization.externalId}}</td>
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
