import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
export default template(`
  <section class="start">
    <PixButton @variant="primary" @triggerAction={{@startAssessment}}>
      Démarrer la simulation
    </PixButton>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
