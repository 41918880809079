import { template } from "@ember/template-compiler";
import AnonymizeGarImport from './anonymize-gar-import';
import OidcProvidersImport from './oidc-providers-import';
export default template(`
  <OidcProvidersImport />
  <AnonymizeGarImport />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
