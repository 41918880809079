import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Searchbar from 'pix-admin/components/complementary-certifications/attach-badges/target-profile-selector/searchbar';
import SelectedTargetProfile from 'pix-admin/components/complementary-certifications/attach-badges/target-profile-selector/selected-target-profile';
export default class TargetProfileSelectorComponent extends Component {
    @service
    notifications;
    @service
    store;
    @tracked
    attachableTargetProfiles = undefined;
    @tracked
    isAttachableTargetProfilesLoading = false;
    @tracked
    selectedTargetProfile;
    @tracked
    isNoResult = false;
    @action
    onChange() {
        this.selectedTargetProfile = undefined;
        this.attachableTargetProfiles = undefined;
        this.args.onChange();
    }
    @action
    async onSearch(value1) {
        this.isNoResult = false;
        this.attachableTargetProfiles = undefined;
        const searchTerm1 = value1?.trim();
        const isSearchById1 = searchTerm1 && /^\d+$/.test(searchTerm1);
        const isSearchByName1 = searchTerm1?.length >= 2;
        if (isSearchById1 || isSearchByName1) {
            try {
                this.isAttachableTargetProfilesLoading = true;
                const attachableTargetProfiles1 = await this.store.query('attachable-target-profile', {
                    searchTerm: searchTerm1
                });
                if (attachableTargetProfiles1.length === 0) {
                    this.isNoResult = true;
                    return;
                }
                this.attachableTargetProfiles = attachableTargetProfiles1.map((attachableTargetProfile1)=>({
                        label: `${attachableTargetProfile1.id} - ${attachableTargetProfile1.name}`,
                        value: attachableTargetProfile1
                    }));
            } catch (e1) {
                this.args.onError('Une erreur est survenue lors de la recherche de profils cibles.');
            } finally{
                this.isAttachableTargetProfilesLoading = false;
            }
        }
    }
    @action
    async onSelection(selectedAttachableTargetProfile1) {
        if (selectedAttachableTargetProfile1?.value?.id) {
            this.selectedTargetProfile = selectedAttachableTargetProfile1.value;
            this.args.onSelection(selectedAttachableTargetProfile1.value);
        }
    }
    static{
        template(`
    {{#if this.selectedTargetProfile}}
      <SelectedTargetProfile @onChange={{this.onChange}} @attachableTargetProfile={{this.selectedTargetProfile}} />
    {{else}}
      <Searchbar
        @onSelection={{this.onSelection}}
        @onSearch={{this.onSearch}}
        @options={{this.attachableTargetProfiles}}
        @isLoading={{this.isAttachableTargetProfilesLoading}}
        @isNoResult={{this.isNoResult}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
