import Namespace from '@ember/application/namespace';
import Ember from 'ember';
import version from "../version.js";
const DS = Namespace.create({
  VERSION: version,
  name: 'DS'
});
if (Ember.libraries) {
  Ember.libraries.registerCoreLibrary('Ember Data', version);
}
export { DS, DS as default };