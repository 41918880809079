import { template } from "@ember/template-compiler";
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
export default class Thematic extends Component {
    get thematicTubes() {
        return this.args.thematic.hasMany('tubes').value();
    }
    get selectedTubeIds() {
        return this.args.selectedTubeIds;
    }
    get isChecked() {
        return this.thematicTubes.any(({ id: id1 })=>this.selectedTubeIds.includes(id1));
    }
    get isIndeterminate() {
        return this.thematicTubes.some(({ id: id1 })=>!this.selectedTubeIds.includes(id1));
    }
    @action
    onChange(event1) {
        const { checked: checked1 } = event1.target;
        if (checked1) {
            this.checkAllTubes();
        } else {
            this.uncheckAllTubes();
        }
    }
    checkAllTubes() {
        this.thematicTubes.forEach((tube1)=>{
            this.args.checkTube(tube1);
        });
    }
    uncheckAllTubes() {
        this.thematicTubes.forEach((tube1)=>{
            this.args.uncheckTube(tube1);
        });
    }
    static{
        template(`
    <th rowspan={{@thematic.tubes.length}}>
      <PixCheckbox
        @id="thematic-{{@thematic.id}}"
        @checked={{this.isChecked}}
        {{on "change" this.onChange}}
        @isIndeterminate={{this.isIndeterminate}}
      >
        <:label>{{@thematic.name}}</:label>
      </PixCheckbox>
    </th>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
