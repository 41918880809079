import { template } from "@ember/template-compiler";
import PixSearchInput from '@1024pix/pix-ui/components/pix-search-input';
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
export default class OrganizationAllTags extends Component {
    @service
    store;
    @tracked
    selectedTag = null;
    @tracked
    recentlyUsedTags = null;
    @tracked
    tagsToShow = [];
    constructor(){
        super(...arguments);
        this.tagsToShow = this.args.model.allTags;
    }
    get allTags() {
        return this.args.model.allTags;
    }
    get debounceTimeInMs() {
        return ENV.pagination.debounce;
    }
    searchInputElementValue(elementId1) {
        return document.getElementById(elementId1).value;
    }
    @action
    async triggerFiltering(elementId1) {
        const valueToSearch1 = this.searchInputElementValue(elementId1).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        this.tagsToShow = this.allTags.filter((tag1)=>tag1.name.toUpperCase().includes(valueToSearch1));
    }
    @action
    async addTagToOrganization(tagToAdd1) {
        const tags1 = await this.args.model.organization.tags;
        tags1.pushObject(tagToAdd1);
        await this.args.model.organization.save();
        this.selectedTag = tagToAdd1;
        this.recentlyUsedTags = await this.store.query('tag', {
            tagId: tagToAdd1.id,
            recentlyUsedTags: true
        });
        tagToAdd1.isTagAssignedToOrganization = true;
    }
    @action
    async addRecentlyUsedTagToOrganization(tagToAdd1) {
        const tags1 = await this.args.model.organization.tags;
        tags1.pushObject(tagToAdd1);
        await this.args.model.organization.save();
        tagToAdd1.isTagAssignedToOrganization = true;
    }
    @action
    async removeTagToOrganization(tagToRemove1) {
        const tags1 = await this.args.model.organization.tags;
        tags1.removeObject(tagToRemove1);
        await this.args.model.organization.save();
        this.selectedTag = null;
        this.recentlyUsedTags = null;
        tagToRemove1.isTagAssignedToOrganization = false;
    }
    static{
        template(`
    <section class="page-section">

      {{#if this.recentlyUsedTags}}
        <h2 class="pix-text--bold pix-text--small">{{t
            "components.organizations.all-tags.recently-used-tags"
            tagName=this.selectedTag.name
          }}</h2>
        <ul class="organization-recently-used-tags-list">
          {{#each this.recentlyUsedTags as |tag|}}
            <li class="organization-recently-used-tags-list__tag">

              {{#if tag.isTagAssignedToOrganization}}
                <button
                  type="button"
                  onClick={{fn this.removeTagToOrganization tag}}
                  aria-label="Tag {{tag.name}} assigné à l'organisation"
                >
                  <PixTag @color="purple-light">
                    {{tag.name}}
                  </PixTag>
                </button>
              {{else}}
                <button
                  type="button"
                  onClick={{fn this.addRecentlyUsedTagToOrganization tag}}
                  aria-label="Tag {{tag.name}} non assigné à l'organisation"
                >
                  <PixTag @color="grey-light">
                    {{tag.name}}
                  </PixTag>
                </button>
              {{/if}}

            </li>
          {{/each}}
        </ul>
      {{/if}}

      <div class="organization-recently-used-tags-list__search-input-container">
        <PixSearchInput
          @screenReaderOnly={{true}}
          @placeholder="Filtrer les tags"
          @debounceTimeInMs={{this.debounceTimeInMs}}
          @triggerFiltering={{this.triggerFiltering}}
        >
          <:label>Filtrer les tags</:label>
        </PixSearchInput>
      </div>

      {{#if this.tagsToShow}}
        <ul class="organization-all-tags-list">
          {{#each this.tagsToShow as |tag|}}
            <li class="organization-all-tags-list__tag">

              {{#if tag.isTagAssignedToOrganization}}
                <button
                  type="button"
                  onClick={{fn this.removeTagToOrganization tag}}
                  aria-label="Tag {{tag.name}} assigné à l'organisation"
                >
                  <PixTag @color="purple-light">
                    {{tag.name}}
                  </PixTag>
                </button>
              {{else}}
                <button
                  type="button"
                  onClick={{fn this.addTagToOrganization tag}}
                  aria-label="Tag {{tag.name}} non assigné à l'organisation"
                >
                  <PixTag @color="grey-light">
                    {{tag.name}}
                  </PixTag>
                </button>
              {{/if}}

            </li>
          {{/each}}
        </ul>
      {{else}}
        <p>Aucun résultat.</p>
      {{/if}}
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
