import { template } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
export default class HabilitationTag extends Component {
    get className() {
        const { active: active1 } = this.args;
        return `${active1 ? '' : 'non-'}granted-habilitation-icon`;
    }
    get icon() {
        const { active: active1 } = this.args;
        return `circle-${active1 ? 'check' : 'xmark'}`;
    }
    static{
        template(`
    <li aria-label={{@arialabel}}>
      <FaIcon class={{this.className}} @icon={{this.icon}} />
      {{@label}}
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
