import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import Areas from '../../common/tubes-selection/areas';
export default class CappedTubesCriterion extends Component {
    @tracked
    selectedTubeIds = [];
    @tracked
    tubeLevels = {};
    @tracked
    areasList = [];
    constructor(){
        super(...arguments);
        Promise.resolve(this.args.areas).then((areas1)=>{
            this.areasList = areas1;
        });
    }
    get areas() {
        return this.areasList.sortBy('code');
    }
    @action
    checkTube(tube1) {
        if (this.selectedTubeIds.includes(tube1.id)) {
            return;
        }
        this.selectedTubeIds.pushObject(tube1.id);
        this._triggerOnChange();
    }
    @action
    uncheckTube(tube1) {
        const index1 = this.selectedTubeIds.indexOf(tube1.id);
        if (index1 === -1) {
            return;
        }
        this.selectedTubeIds.removeAt(index1);
        this._triggerOnChange();
    }
    @action
    setLevelTube(tubeId1, level1) {
        this.tubeLevels = {
            ...this.tubeLevels,
            [tubeId1]: parseInt(level1)
        };
        this._triggerOnChange();
    }
    _triggerOnChange() {
        const selectedTubesWithLevel1 = this._getSelectedTubesWithLevel();
        this.args.onTubesSelectionChange(selectedTubesWithLevel1);
    }
    _getSelectedTubesWithLevel() {
        return this._selectedTubes.map((tube1)=>{
            const level1 = this.tubeLevels[tube1.id] ?? tube1.level;
            return {
                id: tube1.id,
                level: level1
            };
        });
    }
    get _selectedTubes() {
        return (this.areasList.flatMap((area1)=>{
            const competences1 = area1.hasMany('competences').value().toArray();
            return competences1.flatMap((competence1)=>{
                const thematics1 = competence1.hasMany('thematics').value().toArray();
                return thematics1.flatMap((thematic1)=>thematic1.hasMany('tubes').value().toArray());
            });
        }).filter((tube1)=>this.selectedTubeIds.includes(tube1.id)) ?? []);
    }
    static{
        template(`
    <section class="badge-form-criterion">
      <header>
        <h3>Critère d’obtention sur une sélection de sujets du profil cible</h3>
        <PixButton @variant="secondary" @size="small" @triggerAction={{@remove}}>
          Supprimer
        </PixButton>
      </header>
      <main>
        <PixInput @id={{concat @id "criterionName"}} class="badge-form-criterion__name" {{on "change" @onNameChange}}>
          <:label>Nom du critère :</:label>
        </PixInput>
        <PixInput
          @id={{@id}}
          class="badge-form-criterion__threshold"
          type="number"
          min="1"
          max="100"
          @requiredLabel={{t "common.forms.mandatory"}}
          {{on "change" @onThresholdChange}}
        >
          <:label>Taux de réussite requis :</:label>
        </PixInput>
        <Areas
          @areas={{this.areas}}
          @selectedTubeIds={{this.selectedTubeIds}}
          @tubeLevels={{this.tubeLevels}}
          @checkTube={{this.checkTube}}
          @uncheckTube={{this.uncheckTube}}
          @setLevelTube={{this.setLevelTube}}
          @displayDeviceCompatibility={{true}}
        />
      </main>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
