import { template } from "@ember/template-compiler";
import AddOrganizationFeaturesInBatch from './add-organization-features-in-batch';
import LearningContent from './learning-content';
export default template(`
  <LearningContent />
  <AddOrganizationFeaturesInBatch />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
