import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixTextarea from '@1024pix/pix-ui/components/pix-textarea';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import AdministrationBlockLayout from '../block-layout';
export default class CertificationScoringConfiguration extends Component {
    @service
    store;
    @service
    notifications;
    certificationScoringConfiguration = '';
    @action
    onCertificationScoringConfigurationChange(event1) {
        this.certificationScoringConfiguration = event1.target.value;
    }
    @action
    async onCertificationScoringConfigurationSubmit(event1) {
        event1.preventDefault();
        const adapter1 = this.store.adapterFor('scoring-configuration');
        try {
            await adapter1.updateCertificationScoringConfiguration(this.certificationScoringConfiguration);
            this.notifications.success('Configuration enregistrée');
        } catch (e1) {
            this.notifications.error("La config n'a pas pu être ajoutée");
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "pages.administration.certification.certification-scoring-configuration.title"}}
    >
      <form
        class="certification-scoring-configuration-form"
        {{on "submit" this.onCertificationScoringConfigurationSubmit}}
      >
        <PixTextarea
          @id="certification-scoring-configuration"
          rows="4"
          @value={{this.certificationScoringConfiguration}}
          {{on "change" this.onCertificationScoringConfigurationChange}}
        >
          <:label>{{t "pages.administration.certification.certification-scoring-configuration.form.label"}}</:label>
        </PixTextarea>
        <PixButton @type="submit">{{t "common.actions.save"}}</PixButton>
      </form>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
