import { template } from "@ember/template-compiler";
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import Component from '@glimmer/component';
import { categories } from '../../models/target-profile';
export default class Category extends Component {
    get category() {
        const { category: category1 } = this.args;
        return categories[category1];
    }
    static{
        template(`
    <PixTag @color="blue-light">
      {{this.category}}
    </PixTag>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
